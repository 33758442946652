import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import MotomtechLogo from '../../static/assets/Header/motomtech.svg';
import Instagram from '../../static/assets/instagramWhite.svg';
import Linkedin from '../../static/assets/linkedinWhite.svg';
import Twitter from '../../static/assets/twitterWhite.svg';

const isBrowser = typeof window !== 'undefined';
const HeaderComponent = props => {
  const { location, isTransparent } = props;
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    setMenu(false);
  }, [location]);
  useEffect(() => {
    if (document !== 'undefined') {
    }
  }, [menu]);
  const handleClickBars = () => {
    setMenu(!menu);
  };
  const [colorChange, setColorChange] = useState(false);
  const changeNavbarColor = () => {
    if (isBrowser) {
      if (window?.scrollY >= 80) {
        setColorChange(true);
      } else {
        setColorChange(false);
      }
    }
  };
  if (isBrowser) {
    window?.addEventListener('scroll', changeNavbarColor);
  }
  return (
    <header className={colorChange ? ' colorChange' : 'header'}>
      <div className='full-container'>
        <div className=' overflow-visible'>
          <nav
            className={`navbar-warpper ${
              isTransparent ? 'navbar-warpper-transparent' : ''
            }`}
          >
            <Link
              className='nav-item-link navbar-warpper-image'
              to={`/`}
              aria-label='icon'
            >
              <MotomtechLogo className='icon' />
            </Link>
            <div className={`navbar-list ${menu && 'active'}`}>
              <ul className='navbar-list-item'>
                <li className='nav-item nav-item-pricing'>
                  <Link
                    activeStyle={{ color: '#7AFFBF' }}
                    className={`nav-item-link ${
                      isTransparent ? 'nav-item-link-white' : ''
                    }`}
                    to={`/service`}
                  >
                    Services
                  </Link>
                  <div className='border-gradient' />
                </li>
                <li className='nav-item nav-item-pricing'>
                  <Link
                    activeStyle={{ color: '#7AFFBF' }}
                    className={`nav-item-link ${
                      isTransparent ? 'nav-item-link-white' : ''
                    }`}
                    to={`/our-work`}
                  >
                    Our Work
                  </Link>
                  <div className='border-gradient' />
                </li>
                <li className='nav-item nav-item-pricing'>
                  <Link
                    activeStyle={{ color: '#7AFFBF' }}
                    className={`nav-item-link ${
                      isTransparent ? 'nav-item-link-white' : ''
                    }`}
                    to={`/blog`}
                  >
                    Blog
                  </Link>
                  <div className='border-gradient' />
                </li>
                <li className='nav-item nav-item-pricing'>
                  <Link
                    activeStyle={{ color: '#7AFFBF' }}
                    className={`nav-item-link ${
                      isTransparent ? 'nav-item-link-white' : ''
                    }`}
                    to={`/company`}
                  >
                    Company
                  </Link>
                  <div className='border-gradient' />
                </li>
                <li className='nav-item nav-item-pricing'>
                  <Link
                    activeStyle={{ color: '#7AFFBF' }}
                    className={`nav-item-link ${
                      isTransparent ? 'nav-item-link-white' : ''
                    }`}
                    to={`/careers`}
                  >
                    Careers
                  </Link>
                  <div className='border-gradient' />
                </li>
              </ul>
              <div className='contact-mobile'>
                <span
                  className={`loginButton ${
                    isTransparent ? 'lang-change-white mobile-button' : ''
                  }`}
                >
                  <Link
                    activeStyle={{ color: '#7AFFBF' }}
                    className='nav-item-button '
                    to='/contact'
                  >
                    Contact us
                  </Link>
                </span>
              </div>
              <div className='contact-mobile-text'>
                <a
                  href={`tel:${'+1 385 2175 559'}`}
                  className='nav-item-link-contact'
                >
                  <p className='nav-item-link-contact'>+1 385 2175 559</p>
                </a>
                <a
                  href={`mailto:${'imelda@uptechshpk.com'}`}
                  className='nav-item-link-contact'
                >
                  <p className='nav-item-link-contact'>imelda@uptechshpk.com</p>
                </a>
                {/* <div className=' contact-mobile-icon'>
                  <a
                    href='https://www.instagram.com/accounts/login/?next=/motomtech_official/'
                    className='nav-item-icone'
                    rel='noreferrer'
                    target='_blank'
                  >
                    <Instagram className='footer-section-icone-instagram' />
                  </a>

                  <a
                    href='https://twitter.com/motomtech'
                    target='_blank'
                    rel='noreferrer'
                    className='nav-item-icone'
                  >
                    <Twitter className='footer-section-icone-instagram' />
                  </a>
                  <a
                    href='https://www.linkedin.com/company/motomtech-official/'
                    target='_blank'
                    rel='noreferrer'
                    className='nav-item-icone'
                  >
                    <Linkedin className='footer-section-icone-instagram' />
                  </a>
                </div> */}
              </div>
            </div>
            <div className='mobile-nav'>
              <div className='bars'>
                <button
                  onClick={handleClickBars}
                  className={`hamburger--squeeze ${menu ? 'is-active' : ''}`}
                  type='button'
                >
                  <span className='hamburger-box'>
                    <span
                      className={`hamburger-inner ${
                        isTransparent ? 'hamburger-inner-white' : ''
                      }`}
                    />
                  </span>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

HeaderComponent.propTypes = {};

HeaderComponent.defaultProps = {};

export default HeaderComponent;
